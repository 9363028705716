import "../scss/style.scss";
import { createApp } from 'petite-vue';
import { gsap } from 'gsap';
import jQuery from "jquery";
import { MorphSVGPlugin, SplitText, ScrollTrigger } from 'gsap/all';

import './page-headers-marquee';
import './sections-extra-data';
import './industry-insights';
import "./accordion";
import "./lotties";
import "./testimonials";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MorphSVGPlugin, SplitText);

window.$ = window.jQuery = jQuery;

/*
window.addEventListener('resize', () => {
  console.log({ 'ratio': window.innerWidth / window.innerHeight });
});*/

if (document.getElementById('small-animated-logo')) {
  let topLogo = {
    t: document.getElementById('logo-t'),
    i: document.getElementById('logo-I'),
  };

  gsap.set(topLogo.t, { transformOrigin: '50% 50%' });
  gsap.set(topLogo.i, { transformOrigin: '50% 50%' });

  gsap.timeline({
    scrollTrigger: {
      trigger: document.body,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true
    }
  })
    .to(topLogo.t, { rotation: 360, ease: 'none' }, 0)
    .to(topLogo.i, { rotation: (4 * 360), ease: 'none' }, 0);
}



createApp({
  isMenuOpen: false,
  toggleIsMenuOpen(e) {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      menuAnimation.timeScale(1).play();
    } else {
      menuAnimation.timeScale(1.32).reverse();
    }
  },
  isSearchOpen: false,
  toggleIsSearchOpen(e) {
    this.isSearchOpen = !this.isSearchOpen;

    if (this.isSearchOpen) {
      searchAnimation.timeScale(1).play();
    } else {
      searchAnimation.timeScale(1.32).reverse();
    }
  }
}).mount();


const menuAnimation = gsap.timeline({
  paused: true
})
  .set(document.body, { overflow: 'hidden' })
  .set('#main-menu', { autoAlpha: 1 }, 0)
  .to('#menu-bg', { morphSVG: "M1480,768H740c0,0-42.7-110.7,0-384s0-384,0-384h740V768z", duration: .5, ease: "sine.in" }, 0)
  // .to('#menu-bg', { morphSVG: "m56.98,0S-7.7,176,211.65,384s157.33,384,157.33,384h1111.02V0H56.98Z", duration: 0.3, ease: "sine.out" })
  // .to('#menu-bg', { morphSVG: "M66.98 0S-95 171 91 384s126 384 126 384h1263V0H66.98Z", duration: 0.3, ease: "sine.out" })
  .to('#menu-bg', {
    morphSVG: () => {
      if (window.matchMedia("(max-width: 1023px)").matches) {
        return "m0,0v768h1480V0H0Z"
      } else {
        return "m56.98,0S-7.7,176,211.65,384s157.33,384,157.33,384h1111.02V0H56.98Z"
      }
    }, duration: 0.3, ease: "sine.out"
  })
  .fromTo('#main-menu .menu__link', { autoAlpha: 0, y: '100%' }, {
    duration: 0.5,
    autoAlpha: 1,
    y: 0,
    stagger: 0.03,
  }, 0.6)
  .fromTo('#main-menu .submenu__link', { autoAlpha: 0, y: '100%' }, {
    autoAlpha: 1,
    duration: 0.8,
    y: 0,
    stagger: 0.03,
  }, 0.75)
  .to('#menu-burgermenumain_en', {
    "--line-width": 1,
    duration: .15
  }, "<50%");



let search = {
  bg: document.getElementById('search-bg'),
  box: document.getElementById('search-box'),
  input: document.getElementById('search-input'),
  autocomplete: document.getElementById('search-autocomplete')
};

search.elements = search.box.getElementsByClassName('js-search-el');

const searchAnimation = gsap.timeline({
  paused: true, onComplete: function () {
    if (searchAnimation.reversed()) {
      search.input.focus();
    } else {
      search.input.blur();
    }
  }
})
  .set(document.body, { overflow: 'hidden' })
  .set(search.box, { autoAlpha: 1 }, 0)
  .to(search.bg, { morphSVG: "M1480,768H740c0,0-42.7-110.7,0-384s0-384,0-384h740V768z", duration: .5, ease: "sine.in" }, 0)
  // .to(search.bg, { morphSVG: "m56.98,0S-7.7,176,211.65,384s157.33,384,157.33,384h1111.02V0H56.98Z", duration: 0.3, ease: "sine.out" })
  // .to(search.bg, { morphSVG: "M66.98 0S-95 171 91 384s126 384 126 384h1263V0H66.98Z", duration: 0.3, ease: "sine.out" })
  .to(search.bg, {
    morphSVG: () => {
      if (window.matchMedia("(max-width: 1023px)").matches) {
        return "m0,0v768h1480V0H0Z"
      } else {
        return "m56.98,0S-7.7,176,211.65,384s157.33,384,157.33,384h1111.02V0H56.98Z"
      }
    }, duration: 0.3, ease: "sine.out"
  })
  .fromTo(search.autocomplete, { alpha: 0, y: 40 }, { alpha: 1, y: 0, duration: 0.6 })
  .fromTo(search.elements, { alpha: 0, y: 40 }, { alpha: 1, y: 0, duration: 0.6, stagger: 0.1 }, 0.6)
  .to('.search-menu__wrapper', { "--line-width": 1, duration: .15 }, "<50%");

search.input.addEventListener('keyup', function (e) {
  if (search.input.value.length >= 3) {

    let http = new XMLHttpRequest();

    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        search.autocomplete.innerHTML = this.response;
      }
    };
    http.open('POST', templateURL + 'ajax_search.php', true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    http.send('search_val=' + search.input.value);
  }
  else {
    search.autocomplete.innerHTML = '';
  }
});


/* circle animations ============================ */

/* js-circle-element  js-circle-rotate */

let rotatingCircles = document.getElementsByClassName('js-circle-element');

if (rotatingCircles.length > 0) {

  gsap.fromTo(rotatingCircles, { alpha: 0, scale: 0.2, transformOrigin: "50% 50%" }, { scale: 1, alpha: 1, stagger: 0.1, duration: 1.4, /*overwrite:true,*/ ease: 'elastic.out(1.1, 0.85)' });

  let rotatingCircles_text = document.getElementsByClassName('js-circle-rotate');

  if (rotatingCircles_text.length > 0) {
    gsap.to(rotatingCircles_text, { duration: 160, rotation: 360, repeat: -1, ease: 'none' });
  }
}

/* SplitText ======== */

let scrollEl = {};


scrollEl.splitLines = document.getElementsByClassName('js-anim-splitLines');

if (scrollEl.splitLines.length > 0) {
  scrollEl.splitLines_split = [];

  let i, total = scrollEl.splitLines.length;

  for (i = 0; i < total; i++) {
    let _this = scrollEl.splitLines[i];

    scrollEl.splitLines_split[i] = new SplitText(_this, { type: "lines" });
    _this.classList.remove('op-0');

    if (scrollEl.splitLines_split[i].lines.length > 0) {
      gsap.fromTo(scrollEl.splitLines_split[i].lines, { alpha: 0, y: '120%', transformOrigin: '0% 0%' }, {
        y: 0, alpha: 1,
        duration: 1,
        stagger: 0.09,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: _this,
          start: 'top 80%',
          toggleActions: "play none none none",
          once: true
        }
      });
    }
  }
}



scrollEl.parallaxImages = document.getElementsByClassName('js-parallaxImg');

if (scrollEl.parallaxImages.length > 0) {
  let i, total = scrollEl.parallaxImages.length;

  for (i = 0; i < total; i++) {
    let _this = scrollEl.parallaxImages[i];

    gsap.fromTo(_this, {
      y: 0
    }, {
      y: '40%',
      ease: 'none',
      scrollTrigger: {
        trigger: _this.dataset.container,
        start: 'top top',
        end: 'bottom top',
        scrub: true
      }
    });
  }
}



scrollEl.staggerKids = document.getElementsByClassName('js-staggerKids');

if (scrollEl.staggerKids.length > 0) {
  let i, total = scrollEl.staggerKids.length;

  for (i = 0; i < total; i++) {
    let _this = scrollEl.staggerKids[i];

    let kidsF = _this.getElementsByClassName('js-kid-fadein');
    if (kidsF.length > 0) {
      gsap.fromTo(kidsF, { autoAlpha: 0, y: 56 }, {
        autoAlpha: 1, y: 0, duration: 1.2, ease: 'power2.out', stagger: 0.12,
        scrollTrigger: {
          trigger: _this,
          start: 'top 78%',
          toggleActions: "play none none none",
          once: true
        }
      });
    }
  }
}



scrollEl.inView = document.getElementsByClassName('js-inView');

if (scrollEl.inView.length > 0) {
  let i, total = scrollEl.inView.length;

  for (i = 0; i < total; i++) {
    let _this = scrollEl.inView[i];

    gsap.fromTo(_this, {
      y: 56, alpha: 0
    }, {
      y: 0, alpha: 1,
      duration: 0.6,
      ease: 'power3.out',
      scrollTrigger: {
        trigger: _this,
        start: 'top 90%'
      }
    });
  }
}


jQuery('#ns-submit').click(function (e) {
  let formid = jQuery(this).data('formid');
  let reply = jQuery(jQuery(this).data('reply'));
  checkform_ns(formid, reply);
  e.preventDefault();
});




function clear_errors(formid) {
  jQuery("#" + formid + " input, #" + formid + " textarea").each(function () {
    if (jQuery(this).attr('type') != 'hidden' && jQuery(this).attr('type') != 'button' && jQuery(this).attr('type') != 'submit') {
      jQuery(this).removeClass('field-with-error');
      jQuery(this).attr('aria-invalid', false);
    }
  });
}

function show_error(element) {
  element.addClass('field-with-error');
  element.attr('aria-invalid', true);
}

function checkEmail(id) {
  var field = document.getElementById(id);
  var emailExp = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-Z0-9]{2,4}$/;
  if (field.value.length == 0) {
    return false;
  }
  if (field.value.match(emailExp)) {
    return true;
  }
  else {
    return false;
  }
}


function checkform_ns(formid, $reply_holder) {
  clear_errors(formid);
  var flag = 1, id;
  var $this_form = jQuery('#' + formid);

  $this_form.find(".required").each(function () {
    id = jQuery(this).attr("id");
    if (jQuery(this).val() === "" || jQuery(this).val() === document.getElementById(id).defaultValue) {
      flag = 0;
      show_error(jQuery(this));
      $reply_holder.html('<div class="red">' + $this_form.data('error_mandatory') + '</div>');
      return false;
    }
  });

  if (flag === 1) {
    $this_form.find(".email").each(function () {
      id = jQuery(this).attr("id");
      if (checkEmail(id) === false && flag === 1) {
        flag = 0;
        show_error(jQuery(this));
        $reply_holder.html('<div class="red">' + $this_form.data('error_email') + '</div>');
        return false;
      }
    });
  }
  else { return (false); }

  if (flag === 1) {
    $this_form.find(".required-chk").each(function () {
      if (!jQuery(this).prop("checked")) {
        flag = 0;
        $reply_holder.html('<div class="red">' + $this_form.data('error_terms') + '</div>');
        return false;
      }
    });
  }

  if ($this_form.find("#ns-check").val() !== "") {
    flag = 0;
    return false;
  }
  if (flag === 1) {
    var data = $this_form.serialize();

    $reply_holder.html('<div class="loading"></div>');
    jQuery.post(templateURL + "ajax_ns_submit.php", data).done(function (reply) {
      $reply_holder.html(reply);
    });
  }
}