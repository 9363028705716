import { groupBy } from "lodash";

try {
  const SECTIONS = Array.from(document.querySelectorAll('section[data-layout]'))
  const GROUPED_SECTIONS = groupBy(SECTIONS, (e) => e.dataset.layout)

  for (const [group, elements] of Object.entries(GROUPED_SECTIONS)) {
    elements.map((e, i) => {
      e.dataset.groupIndex = i + 1
      e.dataset.evenOdd = i % 2 === 0 ? 'odd' : 'even'
    })
    elements[0].dataset.groupFirst = true
    elements[elements.length - 1].dataset.groupLast = true
  }
} catch (err) {
  console.error(err)
}