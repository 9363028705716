import { gsap } from 'gsap';


try {
  const ACCORDION_HEADERS = document.querySelectorAll('.accordion__title');

  ACCORDION_HEADERS.forEach((header) => {

    header.addEventListener('click', () => {
      let controls = header.getAttribute('aria-controls')
      let panel = document.querySelector(`#${controls}`);
      let expanded = header.getAttribute('aria-expanded') === 'true' || false;

      header.setAttribute('aria-expanded', !expanded);
      panel.hidden = expanded;


      if (!!expanded) {
        gsap.timeline()
          .to(panel, {
            clipPath: "inset(0% 0% 100% 0%)",
            height: "0",
            opacity: 0,
            duration: .5,
            ease: "power1.inOut"
          })
          .set(panel, { display: "none" })
      } else {
        gsap.timeline()
          .fromTo(panel, {
            clipPath: "inset(0% 0% 100% 0%)",
            height: "0",
            opacity: 0,
          }, {
            display: "block",
            clipPath: "inset(0% 0% 0% 0%)",
            height: "auto",
            opacity: 1,
            duration: .75,
            ease: "power1.inOut"
          })
      }
    })
  })

} catch (err) {
  console.error(err);
}